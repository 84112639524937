label{
    color:  #d2143a;; 
    margin-bottom: 0.5rem;
 }
 
 form{
     display: flex;
     flex-direction: column;
     padding: 4rem 1rem;
     margin: auto;
     max-width: 600px;
 }
 
 form input, form textarea{
     margin-bottom: 1rem;
     padding: 10px 18px;
     font-size: 1.2rem;
     background-color:rgba(171, 22, 22, 0.1);
     border-color:  #d2143a;
     color:  #d2143a;;
 }
 