


.box {
	display: inlineBlock;
	box-shadow: 0 5px 10px 2px rgba(240, 11, 64, 0.772);
	padding: 10px;
	border-radius: 30px;
	text-align: center;
	font-weight: bold;
	font-size: 1rem;
}

.calendar {
	
   margin-top: 0%; 
	box-shadow: 0 5px 10px 2px rgba(53, 48, 48, 0.179);
}


.text{
  display: inlineBlock;
  justify-content: center;
    text-align: center;
    margin-left: 1%;
    color: #d2143a;
    
    margin-top: 0%;
}

.text1{
    text-align: center;
    margin-left: 0%;
    margin-bottom: 0%;
    margin-top: 2%;
    color: #d2143a;

    
}
.head{
    background-image: linear-gradient(to bottom right,#dc143c, #dc143c);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    text-align: center;
    font-size: xxx-large;
    font-weight: bold;
    padding: 2%;
  }
  .head-body{
    text-align: center;
    color: #d2143a;
    
  }

  
  .image{
    
    display: inlineBlock;
    justify-content: center;
    height: 600px;
    width: 450px;
    margin-left: 60%;
    margin-bottom: 0%;
    margin-top: 0%;
    
  }
